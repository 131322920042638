import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/manufacturing.jpeg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import ManufacturingImages from "../../components/applicationImageComponents/manufacturingImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const ManufacturingPage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Manufacturing" description="" />
      {/* <Modal style={{ display: modal.display }}>
        <img src={machineImage} />
        <i
          onClick={() => setModal({ display: "none" })}
          className="far fa-times-circle"
        />
        <a style={{ textDecoration: "none" }} href={machineLink}>
          <button id="machine-btn">{model.name} Page</button>
        </a>
      </Modal> */}
      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Manufacturing</h1>
          <p>
            Manufacturing facilities, whether it's metal, plastic, or wood they
            are working with, they are some of our most loyal customers for
            sweepers and scrubbers. Even with the latest dust collection systems
            and modern fabrication equipment, there is something about the
            "manufacturing" process that tends to make a mess, which our
            sweeper-scrubber combo machines are ideally suited for.
            Manufacturing facilities often house industrial machine, material
            handling equipment, large pillars and other items which are
            potential collision hazards.
          </p>
          <h2>We Have A Tough Machine For Your Tough Environment:</h2>
          <ul>
            <li>
              Unlike a battery scrubber built for the soft walls found in
              hospitals or shopping malls, our scrubbers are built to absorb and
              survive such collision.
            </li>
            <li>
              We've also found the industrial applications can require the walk
              behind sweeper or rider scrubber to digest large quantities of
              debris and harsh fluids.
            </li>
            <li>
              Our scrubbers have fully accessible recovery tanks and stainless
              fasteners to handle the most harsh applications.
            </li>
          </ul>
          <ApplicationQuote quote="FactoryCat’s cleaning experts can help you choose the correct size and configured machine to accomplish your cleaning goals. Then FactoryCat custom builds the machine for your application all here in the USA. -JC" />
          <ApplicationQuote quote="These are steel machines with all motors bolted into the plate steel of the frame.  This makes them uniquely durable and easy to service.  Stainless Steel options  for harsh, corrosive environments keep these machines in-service long after other machines have failed. -KB" />
          <ApplicationQuote quote="FactoryCat floor scrubbers and sweepers are famous in the world of manufacturing where floors have layers of grease, dirt, or cutting fluids that need cleaned.  The high rpm scrub motors on both the cylindrical scrub decks and disk scrub decks deliver unsurpassed cleaning performance in one swipe. -JM" />
        </PageBody>
        <ManufacturingImages />

        {/* <StyledImageGallery>
          {data.allContentfulProductImage.edges.map(edge => {
            return (
              <Thumbnail>
                <i className="fas fa-search-plus"></i>
                <img
                  onClick={handleImageClick}
                  src={edge.node.image.fluid.src}
                  alt={edge.node.modelNumber}
                  data-model={edge.node.modelNumber}
                  data-link={edge.node.pageLink}
                />
                <div className="overlay">
                  <h4>
                    Model:{" "}
                    <a href={edge.node.pageLink}>
                      <span style={{ fontWeight: "normal" }}>
                        {edge.node.modelNumber}
                      </span>
                    </a>
                  </h4>
                </div>
              </Thumbnail>
            )
          })}
        </StyledImageGallery> */}
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default ManufacturingPage
